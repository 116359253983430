import { useState, useRef, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import toast from 'react-hot-toast';
import { ImageEditor } from '../../../Modal';
import 'jimp';
import { GroupNameEdit } from './GroupUpdateInputs';
import GroupUserEdit from './GroupUserEdit';
import GenericGroupAvatar from '../../../../constants/logoImages/avatar/groupPlaceholder.png';
import { notAnImage, notSupportedImage } from '../../../Tools/ImageAlerts';
import { axiosHandler, httpErrorHandler } from '../../../../utils/axiosHandler';
import { CurrentUserContext } from '../../../../contexts/CurrentUser';
import SwalAlert, { ErrorListModal } from '../../../Tools/SwalAlert';
import { useHistory } from 'react-router-dom';
import * as Routes from '../../../../constants/routes';

const EditGroup = ({
  group,
  avatar,
  scoringDetails,
  groupPositions,
  positionInfo,
  groupAdmins,
  updateUserlist,
}) => {
  const [modalOpen, updateModalOpen] = useState(false);
  const [groupName, updateGroupName] = useState(group.name);
  const [groupDescription, updateGroupDescription] = useState(
    group.description
  );
  const [groupIsPrivate, updateGroupIsPrivate] = useState(group.private);
  const [rostersHidden, updateRostersHidden] = useState(
    group.hideRostersUntilLocked
  );
  const [tempAvatar, updateTempAvatar] = useState(avatar);
  const [avatarUploaded, updateAvatarUploaded] = useState(false);
  const [fileToCrop, updateFileToCrop] = useState('');
  const [errorMessages, updateErrorMessages] = useState([]);

  const { currentUser } = useContext(CurrentUserContext);
  const history = useHistory();
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (group.avatar !== undefined) {
      if (group.avatar === false) {
        updateTempAvatar(GenericGroupAvatar);
      } else {
        updateTempAvatar(avatar);
      }
    }
  }, [group.avatar]);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    if (name === 'groupDescription') {
      updateGroupDescription(value);
    } else if (name === 'groupName') {
      updateGroupName(value);
    } else if (name === 'groupIsPrivate') {
      updateGroupIsPrivate(target.checked);
    } else if (name === 'rostersHidden') {
      updateRostersHidden(target.checked);
    } else if (name === 'avatar') {
      if (!!target.files[0].type.match('image.*')) {
        const file = target.files[0];
        fileInputRef.current.value = '';
        if (file.type === 'image/webp' || file.type === 'image/svg+xml') {
          notSupportedImage();
          target.value = '';
          return;
        }
        Jimp.read(URL.createObjectURL(file), async (err, img) => {
          if (err) {
            console.log(err);
            toast.error('Error processing image!');
            return;
          }
          const mime = await img.getBase64(Jimp.MIME_JPEG);
          updateFileToCrop(mime);
        });
        updateModalOpen(true);
      } else {
        notAnImage();
        target.value = '';
      }
    }
  };

  const requestCloseModal = () => {
    updateModalOpen(!modalOpen);
    updateTempAvatar(avatar);
  };

  const saveCroppedAvatar = (mime) => {
    updateTempAvatar(mime);
    updateFileToCrop('');
    updateAvatarUploaded(true);
    updateModalOpen(false);
  };

  const transferCommissioner = async (user) => {
    const { isConfirmed } = await SwalAlert({
      html:
        `You're about to <u>transfer ownership</u> of ${group.name} to <strong>${user}</strong>.` +
        '<br/>' +
        '<br/>' +
        `This allows ${user} to make changes and remove users from the group. Removing the privileges from you.` +
        '<br/>' +
        '<br/>' +
        'This <strong>cannot</strong> be undone without the other user updating the group again.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirm transfer',
      confirmButtonColor: '#d9534f',
      cancelButtonColor: '#0275d8',
      reverseButtons: true,
    });
    if (isConfirmed) {
      try {
        await axiosHandler.put(`/api/group/transfer`, {
          groupName: group.name,
          newAdminUsername: user,
        });
        history.go(0);
      } catch (err) {
        httpErrorHandler(err);
        return;
      }
      history.push(Routes.home);
    }
  };

  const removeUser = async (user) => {
    const { isConfirmed } = await SwalAlert({
      html:
        `You're about to remove <strong>${user}</strong> from ${group.name}.` +
        '<br/>' +
        '<br/>' +
        `This will remove their rosters and score records, clearing them from the leaderboard.` +
        '<br/>' +
        '<br/>' +
        '<strong><u>This cannot be undone.</u></strong>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Remove User',
      confirmButtonColor: '#d9534f',
      cancelButtonColor: '#0275d8',
      reverseButtons: true,
    });
    if (isConfirmed) {
      try {
        await axiosHandler.delete(`/api/group/user/${group.name}/${user}`);
        updateUserlist();
      } catch (err) {
        httpErrorHandler(err);
      }
    }
  };

  const revertGroupInfo = () => {
    updateGroupDescription(group.description);
    updateGroupName(group.name);
    updateGroupIsPrivate(group.private);
    updateRostersHidden(group.hideRostersUntilLocked);
    updateTempAvatar(avatar);
  };

  const validateGroupInfo = async () => {
    const errors = [];
    if (groupName.length < 6) {
      errors.push(
        `Name must be at least 6 characters. Currently ${groupName.length}`
      );
    } else if (groupName.length > 8) {
      errors.push(
        `Name must be fewer than 20 characters. Currently ${groupName.length}`
      );
    }
    if (groupDescription.length < 6) {
      errors.push(
        `Description must be at least 6 characters. Currently ${groupDescription.length}`
      );
    } else if (groupDescription.length > 160) {
      errors.push(
        `Description must be fewer than 160 characters. Currently ${groupDescription.length}`
      );
    }
    updateErrorMessages(errors);
    return errors.length > 0;
  };

  const submitGroupInfo = async () => {
    try {
      // if (validateGroupInfo()) {
      // return;
      // }
      const { data } = await axiosHandler.put(
        `/api/group/info/update/${group.name}`,
        {
          name: groupName,
          description: groupDescription,
          private: groupIsPrivate,
          hideRostersUntilLocked: rostersHidden,
        }
      );
    } catch (err) {
      console.log('err: ', { err });
      if (err.status === 400) {
        ErrorListModal(err.response.data);
      } else {
        httpErrorHandler(err);
      }
    }
  };

  return (
    <div className='container'>
      <GroupNameEdit
        header={'Edit Group'}
        errorMessages={errorMessages}
        fileInputRef={fileInputRef}
        tempAvatar={tempAvatar}
        handleChange={handleChange}
        groupName={groupName}
        groupDescription={groupDescription}
        groupIsPrivate={groupIsPrivate}
        rostersHidden={rostersHidden}
        revertGroupInfo={revertGroupInfo}
        submitGroupInfo={submitGroupInfo}
      />

      <div className='mt-5 justify-content-center row'>
        <div className='col-12 col-lg-8 border rounded shadow'>
          Invite People
        </div>
      </div>

      <GroupUserEdit
        currentUser={currentUser}
        userlist={group.userlist}
        transferCommissioner={transferCommissioner}
        removeUser={removeUser}
      />

      <div className='mt-5 justify-content-center row'>
        <div className='col-12 col-lg-8 border rounded shadow'>
          Roster Locked & Roster Edit
        </div>
      </div>

      <div className='mt-5 justify-content-center row'>
        <div className='col-12 col-lg-8 border rounded shadow'>
          Scoring Edit
        </div>
      </div>

      <Modal
        onRequestClose={requestCloseModal}
        isOpen={modalOpen}
        contentLabel='modalWindow'
        className={'modalWindow'}
        overlayClassName='modalOverlay'
        ariaHideApp={false}
      >
        <ImageEditor
          tempAvatar={fileToCrop}
          saveCroppedAvatar={saveCroppedAvatar}
          openCloseModal={requestCloseModal}
          fileInputRef={fileInputRef}
        />
      </Modal>
    </div>
  );
};

export default EditGroup;
