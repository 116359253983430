import { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import Session from '../../../contexts/Firebase/Session/index.js';
import { CurrentUserContext } from '../../../contexts/CurrentUser';
import { NFLScheduleContext } from '../../../contexts/NFLSchedule';
import { axiosHandler, httpErrorHandler } from '../../../utils/axiosHandler.js';
import GroupRow from './GroupRow.js';
import SwalAlert from '../../Tools/SwalAlert.js';

const GroupListPage = () => {
  const [grouplist, updateGroupList] = useState([]);

  const { currentUser } = useContext(CurrentUserContext);
  const { getSeasonAndWeek } = useContext(NFLScheduleContext);

  const axiosCancel = axios.CancelToken.source();

  useEffect(() => {
    return function cancelAPICalls() {
      if (axiosCancel) {
        axiosCancel.cancel('Unmounted');
      }
    };
  }, []);

  useEffect(() => {
    if (currentUser.grouplist) {
      getSeasonAndWeek();
      getGrouplist();
      if (currentUser.grouplist.length === 0) {
        welcomeModal();
      }
    }
  }, [currentUser.grouplist]);

  const welcomeModal = () => {
    SwalAlert({
      title: 'Welcome to the Eliminator!',
      customClass: {
        htmlContainer: 'text-start',
      },
      html: `Here we play fantasy football with a twist:
            <br />
            <br />
            Each week set a lineup while having access to every offensive player in the NFL. 
            <br />
            <br />
            But, you can only play each player one time per season. 
            <br />
            <br />
            Join a group or create one to begin filling out a roster.
            <br />
            Good Luck!`,
    });
  };

  const getGrouplist = async () => {
    try {
      const { data } = await axiosHandler.get(
        '/api/group/list',
        axiosCancel.token
      );
      updateGroupList(data);
    } catch (err) {
      httpErrorHandler(err);
    }
  };

  return (
    <div className='container'>
      <div className='row'>
        <h2 className='col-12 text-center header mt-3'>Active Groups</h2>
      </div>

      <div className='row justify-content-center'>
        <div className='col-12 col-xl-8'>
          {grouplist.map((group) => (
            <GroupRow key={`${group._id}`} group={group} />
          ))}
        </div>
      </div>
      {/* <div className='row  mt-3'>
        <div className='text-muted col-12 text-center'>
          Not seeing a group that interests you?
        </div>
      </div>
      <div className='row mt-1'>
        <div className='col-12 text-center'>
          <Link to={`/group/create`}>
            <button className='btn btn-outline-success btn-sm'>
              Create Group
            </button>
          </Link>
        </div>
      </div> */}
    </div>
  );
};

export default Session(GroupListPage);
