import { Fragment, useState } from 'react';
import ChartLeaderboard from '../../../Leaderboard/ChartLeaderboard';

const NonAdminView = ({
  group,
  avatar,
  leaderboard,
  scoringDetails,
  groupPositions,
  positionInfo,
}) => {
  const [showLeaderboard, setShowLeaderboard] = useState(false);

  const toggleLeaderboard = () => {
    setShowLeaderboard(!showLeaderboard);
  };
  return (
    <>
      <div className='mt-5 justify-content-center row'>
        <div className='col-xs-12 col-lg-8 border rounded shadow'>
          <div className='row mt-3 mb-3 justify-content-center'>
            <div className='col-12 col-lg-6 mt-1 text-center'>
              {avatar && (
                <img
                  name='avatar'
                  className='rounded groupAvatar'
                  alt='Group Avatar'
                  src={avatar}
                />
              )}
            </div>
            <div className='col-11 col-lg-6 mt-1'>
              <div className='row mt-2'>
                <div className='col-12 col-lg-11 border rounded pb-1'>
                  <small className='row'>
                    <div className=' col-12 text-decoration-underline'>
                      Group Name:
                    </div>
                  </small>
                  <div className='row justify-content-center'>
                    <div className='col-11'>{group.name && group.name}</div>
                  </div>
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col-12 col-lg-11 border rounded pb-1'>
                  <div className='row'>
                    <small className='col-12 text-decoration-underline'>
                      Description:
                    </small>
                  </div>
                  <div className='row justify-content-center'>
                    <div className='col-11 groupDescriptionHeight lh-sm'>
                      <div>{group.description && group.description}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col-12 col-lg-11 border rounded pb-1'>
                  <small className='row'>
                    <div className=' col-12 text-decoration-underline'>
                      Group Commissioner:
                    </div>
                  </small>
                  <div className='row justify-content-center'>
                    <div className='col-11'>
                      {group.commissioner && group.commissioner.username}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mt-2 mt-lg-2 justify-content-center'>
                <div className='col-6 text-center'>
                  <button
                    className='btn btn-success btn-sm'
                    onClick={() => toggleLeaderboard()}
                  >
                    Toggle Leaderboard
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showLeaderboard && (
        <div className='row popInAnimation mt-4 justify-content-center'>
          <div className='justify-content-center col-xs-12 col-lg-8 leaderboardContainter border rounded shadow'>
            <ChartLeaderboard leaderboard={leaderboard} />
          </div>
        </div>
      )}
      <div className='row justify-content-center mt-4'>
        <div className='col-xs-12 col-lg-8 border rounded shadow pb-3'>
          <div className='row mt-2 mb-1'>
            <h5 className='col-12 text-center'>Group Scoring System:</h5>
          </div>
          <div className='row mb-1 justify-content-center'>
            <div className='col-12 col-md-8 col-xl-6'>
              <div className='form-check form-switch'>
                <label className='form-check-label' htmlFor='hiddenPlayers'>
                  Group Rosters hidden until week is locked
                </label>
                {group.name && (
                  <input
                    checked={group.hideRostersUntilLocked}
                    onChange={() => {}}
                    className='form-check-input'
                    type='checkbox'
                    id='hiddenPlayers'
                  />
                )}
              </div>
            </div>
          </div>
          <div className='row mb-1 justify-content-center'>
            <div className='col-12 col-md-8 col-xl-6'>
              <div className='form-check form-switch'>
                <label className='form-check-label'>Group is private</label>
                {group.name && (
                  <input
                    checked={group.private}
                    onChange={() => {}}
                    className='form-check-input'
                    type='checkbox'
                  />
                )}
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-8 col-lg-6'>
              <table className='table table-striped table-hover border border-light rounded'>
                <thead>
                  <tr>
                    <th colSpan={2} scope='row' className='text-center'>
                      Roster Structure
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {groupPositions.map((position, i) =>
                    positionInfo.fullNames ? (
                      <tr key={position + i}>
                        <td className='ps-2 fw-bold'>{position.name}</td>
                        <td>{positionInfo.fullNames[position.name]}</td>
                      </tr>
                    ) : (
                      <Fragment key={position + i}></Fragment>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className='row justify-content-center'>
            {scoringDetails.scoringBucketDescription &&
              Object.keys(scoringDetails.scoringBucketDescription).map(
                (bucket) => (
                  <div className='col-12 col-md-8 col-lg-6' key={bucket}>
                    <div className='row justify-content-center pb-1 pe-1'>
                      <div className='col-12'>
                        <table className='table table-striped table-hover border border-light rounded'>
                          <thead>
                            <tr>
                              <th
                                colSpan={2}
                                scope='row'
                                className='text-center'
                              >
                                {
                                  scoringDetails.scoringBucketDescription[
                                    bucket
                                  ]
                                }{' '}
                                Points
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(
                              scoringDetails.scoringDetailDescription[bucket]
                            ).map((detail) => (
                              <tr key={`${bucket}-${detail}`}>
                                <td className='ps-5 ps-lg-4 w-75'>
                                  {
                                    scoringDetails.scoringDetailDescription[
                                      bucket
                                    ][detail]
                                  }
                                </td>
                                <td>
                                  {scoringDetails.scoringPoints[bucket][detail]}{' '}
                                  pt
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NonAdminView;
