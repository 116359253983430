import { useState, useEffect, useContext, Fragment } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { GenericSwitch, PhoneNumberInput } from './ProfileInputs';
import { differencesInObj } from '../../../utils/genericTools';
import { axiosHandler, httpErrorHandler } from '../../../utils/axiosHandler';
import { CurrentUserContext } from '../../../contexts/CurrentUser';
import { Tooltip } from 'react-tooltip';
import SwalAlert from '../../Tools/SwalAlert';

const reminderBase = {
  leaderboardEmail: true,
  reminderEmail: true,
  systemEmail: true,
};

const ReminderUpdateForm = ({ disableAllFields }) => {
  const [updatedFields, setUpdatedFields] = useState(reminderBase);
  const [baseFields, setBaseFields] = useState(reminderBase);
  const [errors, setErrors] = useState([]);
  const [systemEmailWarningShown, updateSystemEmailWarningShown] =
    useState(false);

  const { currentUser } = useContext(CurrentUserContext);

  const axiosCancel = axios.CancelToken.source();

  useEffect(() => {
    return function cancelAPICalls() {
      if (axiosCancel) {
        axiosCancel.cancel(`Unmounted`);
      }
    };
  }, []);

  useEffect(() => {
    if (currentUser._id) {
      pullReminderFields(currentUser._id);
    }
  }, [currentUser]);

  const pullReminderFields = async (userId) => {
    try {
      const { data } = await axiosHandler.get(
        `/api/user/reminderPref/${userId}`,
        axiosCancel.token
      );
      setUpdatedFields(data);
      setBaseFields(data);
    } catch (err) {
      httpErrorHandler(err);
    }
  };

  const confirmSystemEmails = async (e) => {
    if (e.target.checked === false && !systemEmailWarningShown) {
      const answer = await SwalAlert({
        html: 'This makes it so you will no longer receive emails if someone transfers commissioner of a group to you or if someone invites you to a group.',
        showCancelButton: true,
        confirmButtonText: 'Toggle Off',
        confirmButtonColor: '#14A44D',
        reverseButtons: true,
      });
      if (!answer.isConfirmed) {
        return;
      }
      updateSystemEmailWarningShown(true);
    }
    setUpdatedFields({
      ...updatedFields,
      systemEmail: !updatedFields.systemEmail,
    });
  };

  const handleChange = (e) => {
    setUpdatedFields({ ...updatedFields, [e.target.name]: e.target.checked });
  };

  const handleSubmit = async () => {
    const diff = differencesInObj(baseFields, updatedFields);
    if (Object.keys(diff).length === 0) {
      return;
    }

    try {
      await axiosHandler.put(`/api/user/email/settings/${currentUser._id}`, {
        updatedFields: diff,
      });
      setErrors([]);
      pullReminderFields(currentUser._id);
      toast.success('Notification settings updated', {
        position: 'top-right',
        duration: 4000,
      });
    } catch (err) {
      setErrors([err.message]);
    }
  };

  return (
    <div className='row justify-content-center mt-2 mb-3'>
      <Tooltip id='comingSoon' data-tooltip />
      <div className='col-12 col-lg-7 mt-3'>
        {errors.map((error, i) => (
          <div
            key={`reminderError-${i}`}
            className='text-center text-danger mb-2'
          >
            {error}
          </div>
        ))}
        <div className='row'>
          <div className='col-12 text-center'>
            Weekly Notifications from your groups
          </div>
        </div>
        <div className='col-12 border rounded-bottom'>
          <GenericSwitch
            isDisabled={disableAllFields}
            checkedVal={updatedFields.reminderEmail}
            handleChange={handleChange}
            displayName='Reminder Emails'
            htmlName='reminderEmail'
          />
          <GenericSwitch
            isDisabled={disableAllFields}
            checkedVal={updatedFields.leaderboardEmail}
            handleChange={handleChange}
            displayName='Leaderboard Emails'
            htmlName='leaderboardEmail'
          />
        </div>
        <div className='col-12 text-center mt-3'>
          As needed, commissioner or invite emails
        </div>
        <div className='col-12 border rounded-bottom'>
          <GenericSwitch
            isDisabled={disableAllFields}
            checkedVal={updatedFields.systemEmail}
            handleChange={confirmSystemEmails}
            displayName='System Emails'
            htmlName='systemEmail'
          />
        </div>
        <div className='row justify-content-center text-center mt-3'>
          <div className='col-12'>
            <button className='btn btn-success' onClick={handleSubmit}>
              Update Notifiation Settings
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReminderUpdateForm;
