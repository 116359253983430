import { useState, useEffect } from 'react';
import { axiosHandler, httpErrorHandler } from '../../utils/axiosHandler';
import { toast } from 'react-hot-toast';

const EspnPlayerRow = ({ player }) => {
  const [espnIdInput, updateEspnIdInput] = useState('');
  const [isDisabled, updateIsDisabled] = useState(false);

  useEffect(() => {}, [player]);

  const handleInput = (e) => {
    updateEspnIdInput(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      await axiosHandler.put('/api/nfldata/espnId', {
        mySportsId: player.mySportsId,
        espnId: espnIdInput,
      });
      toast.success(`${player.name} updated`);
      updateIsDisabled(true);
    } catch (err) {
      httpErrorHandler(err);
    }
  };
  return (
    <div className='row mt-1' key={player.mySportsId}>
      <div className='col-6'>{player.name}</div>
      <div className='col-4'>
        <input
          disabled={isDisabled}
          type='number'
          value={espnIdInput}
          onChange={handleInput}
        />
      </div>
      <div className='col-2'>
        <button className='btn btn-sm btn-success' onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default EspnPlayerRow;
