import React, { useEffect, useState, useRef, useContext } from 'react';
import * as Routes from './constants/routes';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { withFirebase } from './contexts/Firebase';
import { Toaster } from 'react-hot-toast';
import { Tooltip } from 'react-tooltip';

import SignInOut from './components/SignInOut';
import NavBar from './components/NavBar';
import Home from './components/Home';
import UserProfile from './components/User/Profile';
import Roster from './components/Roster';
import AdminPanel from './components/AdminPanel';
import UsedPlayers from './components/Roster/UsedPlayers';
import GroupList from './components/Group/GroupList';
import CreateGroup from './components/Group/Profile/Create';
import FourOFour from './components/404';
import SidePanel from './components/SidePanel';
import AvatarWrapper from './contexts/Avatars';
import Unsubscribe from './components/Unsubscribe';
import GroupProfile from './components/Group/Profile';
import EspnIdFiller from './components/EspnIdFiller';
import { CurrentUserContext } from './contexts/CurrentUser';
import { NFLScheduleContext } from './contexts/NFLSchedule';
import './styles/profileStyle.css';
import './styles/rosterStyle.css';
import './styles/modalStyle.css';
import './styles/signInOutStyle.css';
import './styles/leaderBoardStyle.css';
import './styles/sidePanelStyle.css';
import './styles/groupStyle.css';
import Tim from './components/Tim';
import SideBarWrapper from './contexts/SideBar';

const App = ({ firebase }) => {
  const {
    currentUser,
    currentGroup,
    pullUserData,
    pullGroupData,
    clearCurrentUserData,
  } = useContext(CurrentUserContext);
  const { getSeasonAndWeek } = useContext(NFLScheduleContext);

  const listener = useRef(null);

  useEffect(() => {
    listener.current = firebase.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        pullUserData();
        getSeasonAndWeek();
      } else {
        clearCurrentUserData();
      }
      return function cleanup() {
        listener();
      };
    });
  }, [firebase]);

  return (
    <BrowserRouter>
      <AvatarWrapper>
        <SideBarWrapper>
          <Tooltip id='injuryTooltip' data-tooltip />
          <Tooltip id='lockTooltip' data-tooltip />
          <Toaster position='top-right' />
          <SidePanel changeGroup={pullGroupData} firebase={firebase} />
          {currentUser.email && <NavBar />}

          <Switch>
            <Route
              exact
              path={Routes.home}
              render={() => <Home group={currentGroup} />}
            />
            <Route path={Routes.adminPanel} render={() => <AdminPanel />} />
            <Route exact path={Routes.groupList} render={() => <GroupList />} />
            <Route path={Routes.signin} render={() => <SignInOut />} />
            <Route path={Routes.signup} render={() => <SignInOut />} />
            <Route path={Routes.userProfile} render={() => <UserProfile />} />
            <Route path={Routes.groupProfile} render={() => <GroupProfile />} />
            <Route path={Routes.roster} render={() => <Roster />} />
            <Route path={Routes.usedPlayers} render={() => <UsedPlayers />} />
            <Route path={Routes.createGroup} render={() => <CreateGroup />} />
            <Route path={Routes.emailPref} render={() => <Unsubscribe />} />
            <Route path={Routes.tim} render={() => <Tim />} />
            {currentUser.admin && (
              <Route
                path={Routes.espnIdFiller}
                render={() => <EspnIdFiller />}
              />
            )}
            <Route render={() => <FourOFour />} />
          </Switch>
        </SideBarWrapper>
      </AvatarWrapper>
    </BrowserRouter>
  );
};

export { CurrentUserContext, NFLScheduleContext };

export default withFirebase(App);
