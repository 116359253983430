import { useState, createContext } from 'react';

const SideBarContext = createContext();

const SideBarWrapper = ({ children }) => {
  const [showSideBar, setShowSideBar] = useState(false);

  const toggleSideBar = () => setShowSideBar(!showSideBar);

  return (
    <SideBarContext.Provider value={{ showSideBar, toggleSideBar }}>
      {children}
    </SideBarContext.Provider>
  );
};

export { SideBarContext };

export default SideBarWrapper;
