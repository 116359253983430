import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Alert = withReactContent(Swal);

const SwalAlert = (options) =>
  Alert.fire({
    showClass: {
      popup: 'fadeIn',
    },
    hideClass: {
      popup: 'fadeOut',
    },
    ...options,
  });

const CloseAlert = () => Alert.close();

const ErrorListModal = async (validationErrors) => {
  SwalAlert({
    title: 'Errors in group',
    icon: 'error',
    html: validationErrors.map((error) => `<br/><br/><small>${error}</small>`),
    showConfirmButton: true,
    confirmButtonText: 'Close',
    showCancelButton: false,
  });
};

export { CloseAlert, ErrorListModal };
export default SwalAlert;
