import SwalAlert, { CloseAlert } from './SwalAlert';

export const loading = () => {
  SwalAlert({
    title: 'Loading',
    imageUrl: 'https://media.giphy.com/media/3o7aDczpCChShEG27S/giphy.gif',
    imageWidth: 200,
    imageHeight: 200,
    imageAlt: 'Loading Football',
    showConfirmButton: false,
    showCancelButton: false,
  });
};

export const doneLoading = () => {
  CloseAlert();
};
