import { useEffect, useState } from 'react';
import {
  axiosHandlerNoValidation,
  httpErrorHandler,
} from '../../utils/axiosHandler';

const Tim = () => {
  const [image, updateImage] = useState('');
  useEffect(() => {
    if (image === '') {
      getImage();
    }
  }, []);
  const getImage = async () => {
    try {
      const { data } = await axiosHandlerNoValidation.get('/api/user/tim');
      console.log({ data });
      updateImage(data);
    } catch (err) {
      httpErrorHandler(err);
    }
  };
  return (
    <div className='container'>
      <div className='row justify-content-center text-center mt-4'>
        <div className='col-12 col-lg-6'>
          <img
            className='rounded'
            src={image}
            style={{ height: '424px', width: '300px' }}
          />
        </div>
      </div>
      <div className='row justify-content-center mt-2'>
        <div className='col-12 col-lg-6 text-center'>
          Thank you for getting us all into football, dad. I hope you're up
          there looking down on our dumb roster choices. We miss you.
        </div>
      </div>
      <div className='row justify-content-center mt-2'>
        <div className='col-12 col-lg-6 text-center'>
          The Eliminator was started because of you, and is forever dedicated to
          you.
        </div>
      </div>
    </div>
  );
};

export default Tim;
