const GroupUserEdit = ({
  currentUser,
  userlist,
  removeUser,
  transferCommissioner,
}) => {
  return (
    <div className='mt-5 justify-content-center row'>
      <div className='col-12 col-lg-8 border rounded shadow'>
        <div className='row mt-2 mb-1'>
          <h5 className='col-12 text-center'>
            Remove Players & Transfer Commissioner
          </h5>
        </div>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-8'>
            <table className='table'>
              <thead>
                <tr className='table-light'>
                  <th scope='col'>Username</th>
                  <th scope='col' className='text-center'>
                    Remove User
                  </th>
                  <th scope='col' className='text-center'>
                    Transfer Ownership
                  </th>
                </tr>
              </thead>
              <tbody>
                {userlist &&
                  userlist.map((user) => (
                    <tr key={user} className='adminUpdatePlayerRow'>
                      <td>{user}</td>
                      <td className='text-center'>
                        {currentUser.username !== user && (
                          <button
                            className='btn btn-sm btn-secondary'
                            onClick={() => {
                              removeUser(user);
                            }}
                          >
                            Remove
                          </button>
                        )}
                      </td>
                      <td className='text-center'>
                        {currentUser.username !== user && (
                          <button
                            className='btn btn-sm btn-secondary'
                            onClick={() => {
                              transferCommissioner(user);
                            }}
                          >
                            Transfer
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupUserEdit;
