import { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { CurrentUserContext } from '../../../../contexts/CurrentUser';
import * as Routes from '../../../../constants/routes';
import { useHistory } from 'react-router-dom';
import Session from '../../../../contexts/Firebase/Session';
import { axiosHandler, httpErrorHandler } from '../../../../utils/axiosHandler';
import GenericGroupAvatar from '../../../../constants/logoImages/avatar/groupPlaceholder.png';
import Modal from 'react-modal';
import { ImageEditor } from '../../../Modal';
import { countDecimals } from '../../../../utils/genericTools';
import {
  GroupNameEdit,
  GroupRosterEdit,
  GroupScoringEdit,
} from '../Edit/GroupUpdateInputs';
import { notAnImage, notSupportedImage } from '../../../Tools/ImageAlerts';
import SwalAlert, { ErrorListModal } from '../../../Tools/SwalAlert';

const CreateGroup = () => {
  const [groupName, updateGroupName] = useState('');
  const [groupDescription, updateGroupDescription] = useState('');
  const [tempAvatar, updateTempAvatar] = useState(GenericGroupAvatar);
  const [avatarUploaded, updateAvatarUploaded] = useState(false);
  const [fileToCrop, updateFileToCrop] = useState('');
  const [modalOpen, updateModalOpen] = useState(false);
  const [rosterSelect, updateRosterSelect] = useState({
    quarterbacks: 1,
    runningbacks: 2,
    widereceivers: 2,
    tightends: 1,
    runningbackWidereceiver: 0,
    flex: 1,
    superFlex: 0,
    kickers: 0,
  });
  const [hidePlayersToggle, updateHidePlayersToggle] = useState(false);
  const [namingErrors, updateNamingErrors] = useState([]);
  const [rosterErrors, updateRosterErrors] = useState([]);
  const [scoringMap, updateScoringMap] = useState({});
  const [enteredScore, updateEnteredScore] = useState({});

  const { currentUser, pullUserData } = useContext(CurrentUserContext);
  const history = useHistory();

  const axiosCancel = axios.CancelToken.source();
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (currentUser.email) {
      getScoringSystem();
    }
    return function cancelAPICalls() {
      if (axiosCancel) {
        axiosCancel.cancel('Unmounted');
      }
    };
  }, [currentUser]);

  useEffect(() => {
    const errors = [];
    if (groupName.trim().length > 20) {
      errors.push('Group name must be shorter than 20 characters');
    }
    if (groupDescription.trim().length > 160) {
      errors.push('Group description must be shorter than 160 characters');
    }
    updateNamingErrors(errors);
  }, [groupName, groupDescription]);

  useEffect(() => {
    const errors = [];
    if (Object.values(rosterSelect).reduce((total, x) => total + x) > 15) {
      errors.push('Must have 15 or less roster spots');
    }
    if (rosterSelect.quarterbacks + rosterSelect.superFlex > 3) {
      errors.push('No more than 3 quarterbacks allowed (including Super Flex)');
    }
    if (
      rosterSelect.runningbacks +
        rosterSelect.runningbackWidereceiver +
        rosterSelect.flex +
        rosterSelect.superFlex >
      7
    ) {
      errors.push(
        'No more than 7 running backs allowed (including RB/WR, Flex, and Super Flex)'
      );
    }
    if (
      rosterSelect.widereceivers +
        rosterSelect.runningbackWidereceiver +
        rosterSelect.flex +
        rosterSelect.superFlex >
      7
    ) {
      errors.push(
        'No more than 7 wide receivers allowed (including RB/WR, Flex, and Super Flex)'
      );
    }
    if (
      rosterSelect.tightends + rosterSelect.flex + rosterSelect.superFlex >
      3
    ) {
      errors.push(
        'No more than 3 tight ends allowed (including Flex and Super Flex)'
      );
    }
    if (rosterSelect.kickers > 2) {
      errors.push('No more than 2 kickers allowed');
    }
    updateRosterErrors(errors);
  }, [
    rosterSelect.quarterbacks,
    rosterSelect.runningbacks,
    rosterSelect.widereceivers,
    rosterSelect.runningbackWidereceiver,
    rosterSelect.flex,
    rosterSelect.superFlex,
    rosterSelect.kickers,
  ]);

  const groupValidationErrors = async () => {
    const errors = [];
    if (namingErrors.length > 0) {
      errors.push(...namingErrors);
    }

    //Only check naming errors for over length, verify smaller here
    if (groupName.length < 6) {
      errors.push('Group name must be at least 6 characters');
    }
    if (groupDescription.length < 6) {
      errors.push('Group description must be at least 6 characters');
    }

    if (rosterErrors.length > 0) {
      errors.push(...rosterErrors);
    }

    for (const bucket of Object.keys(enteredScore)) {
      for (const [innerKey, innerValue] of Object.entries(
        enteredScore[bucket]
      )) {
        if (innerValue === '-' || innerValue === '-.' || innerValue === '.') {
          //Do nothing, will be replaced by 0
        } else if (isNaN(innerValue)) {
          errors.push(
            `Score for ${scoringMap.groupScoreBucketMap[bucket]} - ${scoringMap.groupScoreMap[bucket][innerKey]} is not a number`
          );
        } else if (innerValue < -100) {
          errors.push(
            `Score for ${scoringMap.groupScoreBucketMap[bucket]} - ${scoringMap.groupScoreMap[bucket][innerKey]} is too low, max -100`
          );
        } else if (innerValue > 100) {
          errors.push(
            `Score for ${scoringMap.groupScoreBucketMap[bucket]} - ${scoringMap.groupScoreMap[bucket][innerKey]} is too high, max 100`
          );
        }
        if (countDecimals(innerValue) > 2) {
          errors.push(
            `Score for ${scoringMap.groupScoreBucketMap[bucket]} - ${scoringMap.groupScoreMap[bucket][innerKey]} cannot go past 2 places past the decimal`
          );
        }
      }
    }
    return errors;
  };

  const requestCloseModal = () => {
    updateModalOpen(false);
  };

  const handleSubmit = async () => {
    const validationErrors = await groupValidationErrors();
    if (validationErrors.length > 0) {
      ErrorListModal(validationErrors);
      return;
    }
    const sanitizedScore = {};
    for (const [key] of Object.entries(enteredScore)) {
      sanitizedScore[key] = {};
      for (const [innerKey, innerValue] of Object.entries(enteredScore[key])) {
        if (innerValue === '-' || innerValue === '-.' || innerValue === '.') {
          sanitizedScore[key][innerKey] = 0;
        } else {
          sanitizedScore[key][innerKey] = innerValue;
        }
      }
    }
    try {
      const groupData = {
        userId: currentUser._id,
        groupScore: sanitizedScore,
        groupName: groupName.trim(),
        groupDescription: groupDescription.trim(),
        groupRoster: rosterSelect,
        hideRostersUntilLocked: hidePlayersToggle,
      };
      if (avatarUploaded) {
        groupData.avatar = tempAvatar;
      }
      await axiosHandler.post('/api/group/create', groupData);
    } catch (err) {
      console.log({ err });
      if (err.response.status === 400) {
        ErrorListModal(err.response.data);
      } else {
        httpErrorHandler(err);
      }
    }
  };

  const handleChange = ({ target }) => {
    if (target.name === 'groupDescription') {
      updateGroupDescription(target.value);
    } else if (target.name === 'groupName') {
      updateGroupName(target.value);
    } else if (target.name === 'avatar') {
      if (!!target.files[0].type.match('image.*')) {
        const file = target.files[0];
        fileInputRef.current.value = '';
        if (file.type === 'image/webp' || file.type === 'image/svg+xml') {
          notSupportedImage();
          target.value = '';
          return;
        }
        Jimp.read(URL.createObjectURL(file), async (err, img) => {
          if (err) {
            console.log(err);
            toast.error('Error processing image!');
            return;
          }
          const mime = await img.getBase64(Jimp.MIME_JPEG);
          updateFileToCrop(mime);
        });
        updateModalOpen(true);
      } else {
        notAnImage();
        e.target.value = '';
      }
    }
  };

  const updateGroupsScore = (e) => {
    let { name, value } = e.target;
    if (value === '-' || value === '-.' || value === '.') {
      //Do Nothing
    } else if (isNaN(+value)) {
      return;
    } else if (value > 100) {
      return;
    } else if (value < -100) {
      return;
    } else if (countDecimals(value) > 2) {
      return;
    }
    const [bucket, bucketKey] = name.split('-');
    const updatedScore = { ...enteredScore };
    updatedScore[bucket][bucketKey] = value;
    updateEnteredScore(updatedScore);
  };

  const getScoringSystem = async () => {
    const newGroupScore = {};
    try {
      const { data } = await axiosHandler.get(
        '/api/group/scoring',
        axiosCancel.token
      );
      updateScoringMap(data);
      for (const bucket of data.buckets) {
        newGroupScore[bucket] = {};
        for (const key of data[bucket]) {
          newGroupScore[bucket][key] = data.defaultScores[bucket][key];
        }
      }
      updateEnteredScore(newGroupScore);
    } catch (err) {
      httpErrorHandler(err);
    }
  };

  const saveCroppedAvatar = (mime) => {
    updateTempAvatar(mime);
    updateFileToCrop('');
    updateAvatarUploaded(true);
    updateModalOpen(false);
  };

  const updateRoster = (e) => {
    const tempRosterSelect = { ...rosterSelect };
    tempRosterSelect[e.target.id] = +e.target.value;
    updateRosterSelect(tempRosterSelect);
  };

  return (
    <div className='container'>
      <GroupNameEdit
        header={'Create Group'}
        namingErrors={namingErrors}
        tempAvatar={tempAvatar}
        fileInputRef={fileInputRef}
        handleChange={handleChange}
        groupName={groupName}
        groupDescription={groupDescription}
      />

      <GroupRosterEdit
        rosterErrors={rosterErrors}
        hidePlayersToggle={hidePlayersToggle}
        updateHidePlayersToggle={updateHidePlayersToggle}
        rosterSelect={rosterSelect}
        updateRoster={updateRoster}
      />

      <GroupScoringEdit
        scoringMap={scoringMap}
        enteredScore={enteredScore}
        updateGroupsScore={updateGroupsScore}
      />
      <div className='row mt-5 mb-5'>
        <div className='col-12 text-center'>
          <button className='btn btn-success' onClick={handleSubmit}>
            Create Group
          </button>
        </div>
      </div>
      <Modal
        onRequestClose={requestCloseModal}
        isOpen={modalOpen}
        contentLabel='modalWindow'
        className='modalWindow'
        overlayClassName='modalOverlay'
        ariaHideApp={false}
      >
        <ImageEditor
          tempAvatar={fileToCrop}
          saveCroppedAvatar={saveCroppedAvatar}
          openCloseModal={updateModalOpen}
        />
      </Modal>
    </div>
  );
};

export default Session(CreateGroup);
