import { useContext } from 'react';
import { Link } from 'react-router-dom';

import * as Routes from '../../constants/routes';
import ElimLogo from '../../constants/elimLogos/ElimLogoNoThe.png';
import SmallElimLogo from '../../constants/elimLogos/SmallElimLogoText.png';

import '../../styles/navBarStyle.css';
import { SideBarContext } from '../../contexts/SideBar';

const NavBar = () => {
  const { toggleSideBar } = useContext(SideBarContext);
  return (
    <div
      className={`container-fluid border-bottom border-2 pt-2 pb-2 sticky-top bg-white sticky-z-index-fix`}
    >
      <div
        className='bm-burger-button ps-3 ps-md-4 pt-3 pt-md-4'
        role='button'
        onClick={() => toggleSideBar()}
      >
        <svg viewBox='0 0 100 100' width='45' height='45'>
          <rect width='90' height='16' rx='6'></rect>
          <rect y='30' width='90' height='16' rx='6'></rect>
          <rect y='60' width='90' height='16' rx='6'></rect>
        </svg>
      </div>
      <div className='row justify-content-center'>
        <div className='col-7 d-none d-md-block text-center'>
          <Link to={Routes.home}>
            <img src={ElimLogo} />
          </Link>
        </div>
        <div className='col-8 d-block d-md-none text-center'>
          <Link to={Routes.home}>
            <img src={SmallElimLogo} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
