import { useContext, useEffect, useState } from 'react';
import { CurrentUserContext } from '../../../contexts/CurrentUser';
import { useParams } from 'react-router-dom';
import { axiosHandler, httpErrorHandler } from '../../../utils/axiosHandler';
import axios from 'axios';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import GenericGroupAvatar from '../../../constants/logoImages/avatar/groupPlaceholder.png';
import SwalAlert from '../../Tools/SwalAlert';

const GroupBox = ({ group }) => {
  const [groupBoxInfo, setGroupBoxInfo] = useState({
    groupData: {},
    userScore: {},
    topScore: {},
  });
  const [groupAvatar, setGroupAvatar] = useState('');

  const { currentUser, pullUserData } = useContext(CurrentUserContext);
  const axiosCancel = axios.CancelToken.source();

  useEffect(() => {
    getGroupInfo();
  }, [group]);

  useEffect(() => {
    return function cancelAPICalls() {
      if (axiosCancel) {
        axiosCancel.cancel(`Unmounted`);
      }
    };
  }, []);

  const params = useParams();

  const getGroupInfo = async () => {
    try {
      const { data } = await axiosHandler.get(
        `/api/group/profile/box/${params.name}/${group.name}`,
        axiosCancel.token
      );
      setGroupBoxInfo(data);
      getGroupAvatar(data.groupData);
    } catch (err) {
      httpErrorHandler(err);
    }
  };

  const leaveGroup = async () => {
    let res;
    try {
      res = await SwalAlert({
        title: 'Are you sure about that?',
        text: 'This cannot be reversed',
        showCancelButton: true,
        cancelButtonText: 'Stay in group',
        confirmButtonText: 'Leave group',
        confirmButtonColor: '#ffc107',
        reverseButtons: true,
      });
    } catch (err) {
      toast.error('Error calling delete confirm');
    }
    try {
      if (res.isConfirmed) {
        await axiosHandler.delete(
          `/api/group/leave/user/${group.name}/${params.name}`
        );
        pullUserData();
      }
    } catch (err) {
      httpErrorHandler(err);
    }
  };

  const getGroupAvatar = async (fullGroupData) => {
    try {
      if (fullGroupData.avatar) {
        const { data } = await axiosHandler.get(
          `/api/avatar/groupAvatar/${fullGroupData._id}`
        );
        setGroupAvatar(data);
      } else {
        setGroupAvatar(GenericGroupAvatar);
      }
    } catch (err) {
      httpErrorHandler(err);
    }
  };

  return (
    <div className='border-bottom'>
      <div className='row mt-3'>
        <h4 className='col-12 text-center'>
          <Link to={`/profile/group/${groupBoxInfo.groupData.name}`}>
            {groupBoxInfo.groupData.name}
          </Link>
        </h4>
      </div>

      <div className='row mt-1'>
        <div className='col-12 col-lg-4 ps-4'>
          <div className='row'>
            <div className='col-12 text-center'>
              {groupAvatar !== '' && (
                <img
                  className='rounded mw-100 mt-lg-1 mb-lg-1 smallGroupAvatar'
                  name='avatar'
                  src={groupAvatar}
                />
              )}
            </div>
          </div>
        </div>
        <div className='col-12 col-lg-8 pt-1'>
          <div className='row justify-content-center pb-1 mb-1 '>
            <div className='col-12 border-bottom'>
              {groupBoxInfo.groupData.description}
            </div>
          </div>
          <div className='row border-bottom pb-1'>
            <div className='col-4 fw-bold'>Top Score</div>
            <div className='col-4'>{groupBoxInfo.topScore.username}</div>
            <div className='col-4'>{groupBoxInfo.topScore.totalScore}</div>
          </div>
          <div className='row mt-1'>
            <div className='col-4 fw-bold'>
              {params.name === currentUser.username
                ? 'Your'
                : params.name.slice(-1) === 's'
                ? `${params.name}'`
                : `${params.name}'s`}{' '}
              Score
            </div>
            <div className='col-4'>{groupBoxInfo.userScore.username}</div>
            <div className='col-4'>{groupBoxInfo.userScore.totalScore}</div>
          </div>
        </div>
        {params.name === currentUser.username && (
          <div className='col-12 text-center mt-2 mb-3'>
            <button className='btn btn-warning' onClick={() => leaveGroup()}>
              Leave Group
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupBox;
