import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SwalAlert from '../Tools/SwalAlert';
import {
  axiosHandlerNoValidation,
  httpErrorHandler,
} from '../../utils/axiosHandler';
import { GenericSwitch } from '../User/Profile/ProfileInputs';

const Unsubscribe = () => {
  const [emailSettings, updateEmailSettings] = useState({
    leaderboardEmail: false,
    systemEmail: false,
    reminderEmail: false,
  });

  const axiosCancel = axios.CancelToken.source();
  const params = useParams();

  useEffect(() => {
    if (params.userId) {
      getUserReminderInfo(params.userId);
    }
  }, [params.userId]);

  const getUserReminderInfo = async (userId) => {
    try {
      const { data } = await axiosHandlerNoValidation.get(
        `/api/user/email/settings/${userId}`,
        axiosCancel.token
      );
      updateEmailSettings(data);
    } catch (err) {
      httpErrorHandler(err);
    }
  };

  const handleChange = (e) => {
    updateEmailSettings({
      ...emailSettings,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSubmit = async () => {
    try {
      await axiosHandlerNoValidation.put(
        `/api/user/email/unsubscribe/${params.userId}`,
        emailSettings
      );
    } catch (err) {
      httpErrorHandler(err);
    }
  };

  return (
    <div className='container'>
      <div className='row mt-4'>
        <h1 className='col-12 text-center'>Email Settings</h1>
      </div>
      <div className='row justify-content-center mt-3'>
        <div className='col-12 col-lg-6 border rounded'>
          <div className='row mt-1'>
            <div className='col-12'>
              <GenericSwitch
                isDisabled={false}
                checkedVal={emailSettings.leaderboardEmail}
                handleChange={handleChange}
                displayName='Leaderboard Emails'
                htmlName='leaderboardEmail'
              />
            </div>
          </div>
          <div className='row mt-2 mb-1'>
            <div className='col-12 text-center'>
              Weekly emails with the leaderboard of the group. Includes the
              total and last week score info for everyone in the group. Also
              includes your roster and the ideal roster from last week
            </div>
          </div>
        </div>
      </div>
      <div className='row justify-content-center mt-3'>
        <div className='col-12 col-lg-6 border rounded'>
          <div className='row mt-1'>
            <div className='col-12'>
              <GenericSwitch
                isDisabled={false}
                checkedVal={emailSettings.reminderEmail}
                handleChange={handleChange}
                displayName='Reminder Emails'
                htmlName='reminderEmail'
              />
            </div>
          </div>
          <div className='row mt-2 mb-1'>
            <div className='col-12 text-center'>
              Weekly emails on Thursday indicating if you still have empty
              roster spots for the week. Includes a link to go to your roster to
              fill it out.
            </div>
          </div>
        </div>
      </div>
      <div className='row justify-content-center mt-3 mb-3'>
        <div className='col-12 col-lg-6 border rounded'>
          <div className='row mt-1'>
            <div className='col-12'>
              <GenericSwitch
                isDisabled={false}
                checkedVal={emailSettings.systemEmail}
                handleChange={handleChange}
                displayName='System Emails'
                htmlName='systemEmail'
              />
            </div>
          </div>
          <div className='row mt-2 mb-1'>
            <div className='col-12 text-center'>
              As needed emails if someone transfers commissioner of a group to
              you or if someone invites you to a group.
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 text-center'>
          <button className='btn btn-success' onClick={() => handleSubmit()}>
            Update Email Settings
          </button>
        </div>
      </div>
      {/* {successfulUnsubscribe ? (
              <p>
                You have been successfully unsubscribed from our email list.
              </p>
            ) : (
              <p>Error Unsubscribing Contact Kevin - smushedcode@gmail.com</p>
            )} */}
    </div>
  );
};

export default Unsubscribe;
