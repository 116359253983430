import SwalAlert from './SwalAlert';

const notSupportedImage = () => {
  SwalAlert({
    title: 'Webp & SVG not supported',
    text: 'webp & SVG files are not currently supported by editor, sorry for the inconvenience',
    showCancelButton: true,
    showConfirmButton: false,
  });
};

const notAnImage = () => {
  SwalAlert({
    title: `Only images allowed`,
    text: `File is not an image. Please only upload images`,
    showConfirmButton: false,
    showCancelButton: true,
  });
};

export { notSupportedImage, notAnImage };
