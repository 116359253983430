const GroupNameEdit = ({
  header,
  errorMessages,
  tempAvatar,
  fileInputRef,
  handleChange,
  groupName,
  groupDescription,
  groupIsPrivate,
  rostersHidden,
  revertGroupInfo,
  submitGroupInfo,
}) => {
  return (
    <div className='mt-5 justify-content-center row'>
      <div className='col-xs-12 col-lg-8 border rounded shadow'>
        <div className='row'>
          <h3 className='col-12 text-center mt-2'>{header}</h3>
        </div>

        {errorMessages.length > 0 &&
          errorMessages.map((error, i) => (
            <div className='row' key={`namingError-${i}`}>
              <div className='col-12 text-center'>
                <small className='text-danger'>{error}</small>
              </div>
            </div>
          ))}

        <div className='row justify-content-center'>
          <div className='col-md-12 col-lg-6 mt-1 text-center'>
            <div className='row'>
              <div className='col-12'>
                <img
                  className='rounded mt-1 groupAvatar'
                  name='avatar'
                  src={tempAvatar}
                />
              </div>
            </div>
            <div className='row mt-2 mb-3'>
              <div className='col-12'>
                <label className='btn btn-sm btn-success'>
                  <input
                    type='file'
                    hidden={true}
                    ref={fileInputRef}
                    name='avatar'
                    onChange={handleChange}
                  />
                  Upload Avatar
                </label>
              </div>
            </div>
          </div>

          <div className='col-md-12 col-lg-6 mt-2'>
            <div className='row'>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-12 fw-semibold'>Group Name</div>
                </div>
                <div className='row'>
                  <small className='col-11 ms-2'>
                    Must be between 6 and 20 characters
                  </small>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <input
                      className='form-control'
                      type='text'
                      name='groupName'
                      placeholder='Dragons of Doom'
                      value={groupName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-3 mt-2'>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-12 fw-semibold'>Group Description</div>
                </div>
                <div className='row'>
                  <small className='col-11 ms-2'>
                    Must be between 6 and 160 characters
                  </small>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <textarea
                      className='form-control'
                      type='text'
                      name='groupDescription'
                      placeholder='Burninating the Competition'
                      value={groupDescription}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row justify-content-center'>
            <div className='col-12 col-md-8 col-xl-6'>
              <div className='form-check form-switch'>
                <label
                  className='form-check-label user-select-none'
                  htmlFor='rostersHidden'
                  role='button'
                >
                  Rosters hidden until week is locked
                </label>
                <input
                  checked={rostersHidden}
                  onChange={handleChange}
                  className='form-check-input'
                  type='checkbox'
                  name='rostersHidden'
                  id='rostersHidden'
                  role='button'
                />
              </div>
            </div>
          </div>
          <div className='row justify-content-center mb-3'>
            <div className='col-12 col-md-8 col-xl-6'>
              <div className='form-check form-switch '>
                <label
                  className='form-check-label user-select-none'
                  htmlFor='groupIsPrivate'
                  role='button'
                >
                  Private, group will not show up on searches. Can only be
                  joined via invite
                </label>
                <input
                  checked={groupIsPrivate}
                  onChange={handleChange}
                  className='form-check-input mt-3'
                  type='checkbox'
                  name='groupIsPrivate'
                  id='groupIsPrivate'
                  role='button'
                />
              </div>
            </div>
          </div>
          <div className='row justify-content-center mb-2'>
            <div className='col-3 text-center'>
              <button
                className='btn btn-secondary'
                onClick={() => revertGroupInfo()}
              >
                Revert
              </button>
            </div>
            <div className='col-3 text-center'>
              <button
                className='btn btn-success'
                onClick={() => submitGroupInfo()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const GroupRosterEdit = ({
  rosterErrors,
  hidePlayersToggle,
  updateHidePlayersToggle,
  rosterSelect,
  updateRoster,
}) => {
  return (
    <div className='mt-5 justify-content-center row'>
      <div className='col-xs-12 col-lg-8 border rounded shadow'>
        <div className='row mt-3'>
          <h3 className='col-12 text-center'>Roster</h3>
        </div>

        {rosterErrors.length > 0 &&
          rosterErrors.map((error, i) => (
            <div key={`rosterError-${i}`} className='row'>
              <div className='col-12 text-center mb-1'>
                <small className='text-danger'>{error}</small>
              </div>
            </div>
          ))}

        <div className='row mb-2 justify-content-center'>
          <div className='col-11 pt-1 pb-2 border rounded'>
            <div className='row justify-content-center'>
              <div className='col-12 col-lg-6 ps-4'>
                <div className='form-check form-switch'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='hideRostersUntilLocked'
                    value={hidePlayersToggle}
                    onClick={() => updateHidePlayersToggle(!hidePlayersToggle)}
                  />
                  <label
                    className='form-check-label fw-semibold'
                    htmlFor='hideRostersUntilLocked'
                  >
                    Hide rosters until week is locked
                  </label>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 text-center text-lg-start'>
                <small>
                  Weeks lock every Tuesday morning. This will show rosters on
                  the leaderboards from the previous week rather than current
                  week.
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className='row justify-content-center'>
          <div className='col-12 col-lg-4'>
            <ul className='list-group list-group-flush text-center text-lg-start'>
              <li className='list-group-item'>
                <small>15 Players Max</small>
              </li>
              <li className='list-group-item'>
                <small>3 Quarterbacks Max</small>
              </li>
              <li className='list-group-item'>
                <small>7 Running Backs Max</small>
              </li>
              <li className='list-group-item'>
                <small>7 Wide Receivers Max</small>
              </li>
              <li className='list-group-item'>
                <small>3 Tight Ends Max</small>
              </li>
              <li className='list-group-item'>
                <small>2 Kickers Max</small>
              </li>
              <li className='list-group-item' />
            </ul>
          </div>
          <div className='col-12 col-md-8 col-lg-6'>
            <div className='row mt-1'>
              <div className='col-6 pt-1 text-end'>Quaterbacks (QB)</div>
              <div className='col-4'>
                <select
                  className='form-select'
                  id='quarterbacks'
                  value={rosterSelect.quarterbacks}
                  onChange={updateRoster}
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                </select>
              </div>
            </div>
            <div className='row mt-1'>
              <div className='col-6 col-lg-6 pt-1 text-end'>
                Running Backs (RB)
              </div>
              <div className='col-4'>
                <select
                  className='form-select'
                  id='runningbacks'
                  value={rosterSelect.runningbacks}
                  onChange={updateRoster}
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                </select>
              </div>
            </div>
            <div className='row mt-1'>
              <div className='col-6 pt-1 text-end'>Wide Receivers (WR)</div>
              <div className='col-4'>
                <select
                  className='form-select'
                  id='widereceivers'
                  value={rosterSelect.widereceivers}
                  onChange={updateRoster}
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                </select>
              </div>
            </div>
            <div className='row mt-1'>
              <div className='col-6 pt-1 text-end'>Tight Ends (TE)</div>
              <div className='col-4'>
                <select
                  className='form-select'
                  id='tightends'
                  value={rosterSelect.tightends}
                  onChange={updateRoster}
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                </select>
              </div>
            </div>
            <div className='row mt-1'>
              <div className='col-6 pt-1 text-end'>RB / WR</div>
              <div className='col-4'>
                <select
                  className='form-select'
                  id='runningbackWidereceiver'
                  value={rosterSelect.runningbackWidereceiver}
                  onChange={updateRoster}
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                </select>
              </div>
            </div>
            <div className='row mt-1'>
              <div className='col-6 pt-1 text-end'>Flex (RB/WR/TE)</div>
              <div className='col-4'>
                <select
                  className='form-select'
                  id='flex'
                  value={rosterSelect.flex}
                  onChange={updateRoster}
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                </select>
              </div>
            </div>
            <div className='row mt-1 mb-3'>
              <div className='col-6 pt-1 text-end'>
                Super Flex (QB/RB/WR/TE)
              </div>
              <div className='col-4'>
                <select
                  className='form-select'
                  id='superFlex'
                  value={rosterSelect.superFlex}
                  onChange={updateRoster}
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                </select>
              </div>
            </div>
            <div className='row mt-1 mb-3'>
              <div className='col-6 pt-1 text-end'>Kickers</div>
              <div className='col-4'>
                <select
                  className='form-select'
                  id='kickers'
                  value={rosterSelect.kickers}
                  onChange={updateRoster}
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const GroupScoringEdit = ({ scoringMap, enteredScore, updateGroupsScore }) => {
  return (
    <div className='mt-5 justify-content-center row'>
      <div className='col-xs-12 col-lg-8 border rounded shadow'>
        <div className='row mt-3'>
          <h3 className='col-12 text-center'>Scoring</h3>
        </div>
        <div className='row mb-1'>
          <div className='col-12'>
            <div className='row'>
              <div className='col-12 text-center'>
                <small>
                  Scores must range from -100 to 100 and cannot go more than 2
                  places past the decimal
                </small>
              </div>
            </div>
            <div className='row justify-content-lg-evenly mt-1 mb-1'>
              {scoringMap.buckets &&
                scoringMap.buckets.map((bucket) => (
                  <div
                    key={`${bucket}-bucket`}
                    className='col-12 col-lg-5 mt-3 pb-2 border rounded'
                  >
                    <div className='row mb-1'>
                      <div className='col-12 fw-semibold text-center'>
                        {scoringMap.groupScoreBucketMap[bucket]}
                      </div>
                    </div>
                    {Object.keys(scoringMap.groupScoreMap[bucket]).map(
                      (scoreKey) => (
                        <div key={`${scoreKey}-detail`} className='row'>
                          <div className='col-6 pt-1 text-end'>
                            {scoringMap.groupScoreMap[bucket][scoreKey]}
                          </div>
                          <div className='col-6'>
                            <input
                              className='form-control'
                              type='text'
                              name={`${bucket}-${scoreKey}`}
                              value={enteredScore[bucket][scoreKey]}
                              onChange={updateGroupsScore}
                            />
                          </div>
                        </div>
                      )
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { GroupNameEdit, GroupRosterEdit, GroupScoringEdit };
